import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IframeTrackerDirective } from '../../shared/dir/iframe-tracker.directive';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-test',
  templateUrl: './Iframe.component.html',
  styleUrl: './iframe.component.scss',
  standalone: true,
  imports: [IframeTrackerDirective]
})
export class iframeComponent {

  url: any = '';
  isClicked: boolean = false;
  clickCount: number = 1;

  constructor(private _sanitizer: DomSanitizer,
    private route: ActivatedRoute) {
    //read query params
    this.route.queryParams.subscribe(params => {
      this.url = this._sanitizer.bypassSecurityTrustResourceUrl(params["url"]);
      this.clickCount = params["count"] != null ? parseInt(params["count"]) : 1;
      this.onClick(null, true);
      // document.getElementById('iframe')?.focus();
    });
  }

  ngOnInit() {

  }
  onClick(event: any, skip: boolean = false) {
    console.log('clickCount', this.clickCount);
    if (this.clickCount && !skip) {
      this.clickCount--;
    }
    if (this.clickCount == 0) {

      this.isClicked = true;
    }
  }
}
