<div class="video-container" [class.playing]="isVideoPlaying">
  <video id="my_video_1" (ended)="onVideoEnd()" (play)="handleStarted()" (seeking)="handleSeeking($event)" playsinline>
    <source [src]="videoUrl" type="video/mp4">
  </video>
  <div class="background" [style.background-image]="imagePath == '' ? 'none' : 'url('+imagePath+')'"></div>
  <img src="./../../assets/images/play-button.png" (click)="startPlaying()">
  <button (click)="skip(10)" id="skipper" hidden></button>
</div>


<div id="current-status" hidden>{{currentStatus}}</div>
<div id="video-time" hidden>{{videoTime}}</div>
<div id="video-started" hidden>{{videoStarted}}</div>