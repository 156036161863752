import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { StorageService } from '../services/storage.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const _Router = inject(Router);
  const storageService = inject(StorageService) as StorageService; // Assert the type of storageService

  // Logic to verify token here
  if (storageService.token) {
    return true;
  } else {
    _Router.navigate(['/login']);
    return false;
  }
};
