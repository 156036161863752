import { Component, OnInit } from '@angular/core';
import { DataService } from '../../core/services/data.service';
declare var $: any;

@Component({
  selector: 'app-msg-popup',
  standalone: true,
  imports: [],
  templateUrl: './msg-popup.component.html',
  styleUrls: ['./msg-popup.component.scss']
})
export class MsgPopupComponent implements OnInit {

  show: boolean = false;
  status: string = 'success';
  msg: string = '';
  timer: any;
  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.dataService.msgState().subscribe(data => {
      if (data) {
        this.show = true;
        this.status = data.status;
        this.msg = data.msg;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.show = false;
        }, 4000);
      }
    });
  }
  hide(){
    this.show = false;
  }

}
