import { Component, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DataService } from './core/services/data.service';
import { StorageService } from './core/services/storage.service';
import { MsgPopupComponent } from './shared/msg-popup/msg-popup.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MsgPopupComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'freestore';
 
  loading: boolean = false;
  constructor(private dataService: DataService,
    private storage:StorageService) {

  }

  ngOnInit(): void {
    this.dataService.loadingStatus().subscribe(data => {
      if (data != null) {
        setTimeout(() => {
          this.loading = data;
        }, 50);
      }
    });

  }

}

