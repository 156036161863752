import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { StorageService } from '../services/storage.service';


export const loginGuard: CanActivateFn = async (route, state) => {
  const _Router = inject(Router);
  const storageService: StorageService = inject(StorageService);

  // Logic to verify token here
  if ((!storageService.token)) {
    return true;
  } else {
    _Router.navigate(['']);
    return false;
  }
};
