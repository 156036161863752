@if (show) {
<div class="msg-container" (click)="hide()">
    @if (status == 'success') {
        <img src="./../../../assets/icons/success-icon.svg">
    }
    @if (status == 'fail') {
        <img src="./../../../assets/icons/error-icon.svg">
    }
    <div class="msg">
        {{msg}}
    </div>
</div>
}