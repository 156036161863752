import { Routes } from '@angular/router';
import { VideoComponent } from './public/video/video.component';
import { iframeComponent } from './public/iframe/iframe.component';
import { authGuard } from './core/guards/auth.guard';
import { loginGuard } from './core/guards/login.guard';

export const routes: Routes = [
    { path: 'video-player/:id', component: VideoComponent },
    { path: 'iframe', component: iframeComponent },

    { path: '', redirectTo: 'clients', pathMatch: 'full' },
    {
        path: '',
        canActivate: [authGuard],
        loadChildren: () => import('./modules/layout/layout.routes').then(m => m.layoutsRoutes)
    },
    {
        path: '',
        canActivate: [loginGuard],
        loadChildren: () => import('./modules/auth/auth.routes').then(m => m.authRoutes)
    },
];
