import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { Env } from '../../../env/env';

@Component({
  selector: 'app-video',
  standalone: true,
  imports: [YouTubePlayerModule],
  templateUrl: './video.component.html',
  styleUrl: './video.component.scss'
})
export class VideoComponent implements OnInit {
  videoUrl: any = "";
  @ViewChild('my_video_1', { static: false }) videoPlayer: any;

  previousTime: any = 0;
  videoId: string = '';

  currentStatus: number = 0;
  videoTime: number = 0;
  player: any;
  isVideoPlaying: boolean = false;
  videoStarted: boolean = false;

  imagePath: string = '';

  constructor(private _sanitizer: DomSanitizer,
    private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.videoId = params["id"];
      this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(Env.storageUrl + 'uploads/advertisements/' + this.videoId);
    });

    this.route.queryParams.subscribe(params => {
      this.imagePath = params["image"] != null ? Env.storageUrl + params["image"] : '';
    });
  }
  ngOnInit(): void {


  }
  onVideoEnd() {
    // this.videoWatched = true;
  }
  playPause() {
    var myVideo: any = document.getElementById("my_video_1");
    if (myVideo.paused) myVideo.play();
    // else myVideo.pause();    
  }
  skip(value: number) {
    let video: any = document.getElementById("my_video_1");
    video.currentTime += value;
  }
  startPlaying() {
    setTimeout(() => {
      // this.videoPlayer?.nativeElement.play();

      // let video: any = document.getElementById("videoPlayer");
      // video.currentTime += 1000;
      // console.log(video.currentTime);
      this.playPause();
    }, 0);
  }
  handleStarted() {
    this.isVideoPlaying = true;
    if (this.previousTime == 0) {
      var myVideo: any = document.getElementById("my_video_1");

      setInterval(() => {
        var myVideo: any = document.getElementById("my_video_1");
        this.videoStarted = true;
        this.videoTime = myVideo.duration;
        this.previousTime = myVideo.currentTime;
        this.currentStatus = myVideo.currentTime;
      }, 500);
    }
  }
  handleSeeking(event: any) {

    if (event.target.currentTime > this.previousTime) {
      event.target.currentTime = this.previousTime;
    } else {
      this.previousTime = event.target.currentTime;
    }
  }
}
